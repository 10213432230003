import { toastController, ToastOptions } from "@ionic/vue";
import { informationCircleOutline } from "ionicons/icons";

type Color =
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "danger"
    | "info"
    | "light"
    | "medium"
    | "dark";

export async function toast(
    message: string,
    icon = informationCircleOutline,
    toastOptions: ToastOptions & { color: Color } = { color: "info" }
) {
    const _toast = await toastController.create({
        message,
        icon: icon,
        duration: 2000,
        cssClass: "mirrorworld-toast",
        ...toastOptions,
        mode: "ios"
    });
    return _toast.present();
}